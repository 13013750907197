import React, {useEffect, useRef, useState, useLayoutEffect} from "react"
import Gabe from "../components/gabe"
import Layout from "../components/layout"
import BackgroundSlider, {Pagination} from "gatsby-image-background-slider"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"

const initBGStyle = {
	background:'linear-gradient(rgba(31, 31, 31, 1), rgba(31, 31, 31, 1))', 
	width: "100%", height: "100%", 
	backgroundSize: "cover", 
	position: "absolute", top: 0, zIndex: -2,
	transition: "opacity 2s"
}

const bgAnnotationStyle = {
	position: "absolute", left: "20px", bottom: "calc(20px + .5vw)", zIndex: 10, fontSize: "calc(1vw + 15px)", maxFontSize:"24pt"
}

let straySubStyle = Object.assign({}, bgAnnotationStyle);
straySubStyle.opacity = 0;
straySubStyle.pointerEvents = "none";
straySubStyle.transition = "opacity 4s";

const Link = props => <a rel="noopener noreferrer" {...props}>{props.children}</a>

const IndexPage = () => {
	const query = useStaticQuery(graphql`
		query {
			backgrounds: allFile (filter: {sourceInstanceName: {eq: "backgrounds"}}){
				nodes {
					relativePath
					childImageSharp {
						fluid (maxWidth: 4000, quality: 100){
							...GatsbyImageSharpFluid_withWebp_tracedSVG
						}
					}
				}
			}
		}
	`)

	let bgDivRef = React.createRef();
	const straySub = React.createRef();

	let setBg = {};
	let dots = [];	
	
	const Buttons = () => {
		return (
			<>
				{Array.from(Array(setBg.getCount()).keys()).map ((dot) =>
					<button ref={buttonRefs[dot] = React.createRef()} className={dot === 0 ? 'hit' : ''} onClick={() => setBg.atIndex(dot)}>{dot + 1}</button>
				)}
			</>
		);
	}

	const initEffect = () => {
		setTimeout(()=>{
			bgDivRef.current.style.opacity = .35;
		}, 5000)
	
		setTimeout(()=>{
			straySub.current.style.opacity = 1;
			straySub.current.style.pointerEvents = "auto";			
		}, 6000)

		// setBg.onChange = (prevIndex, newIndex) => { buttonRefs[prevIndex].current.classList.remove('hit'); buttonRefs[newIndex].current.className = 'hit'; };	
	}
	// useForceUpdate();
	
	useEffect(initEffect, []);
	let buttonRefs = [];

	const FadeIn = ({children}) => {
		const myRef = React.createRef();
		setTimeout(() => {
		  myRef.current.style.opacity = 1;
		  myRef.current.style.pointerEvents = "auto";
		}, 6000);
		return (
		  <div ref={myRef} style={{opacity: 0, transition: "opacity 4s", pointerEvents:"none"}}>{children}</div>
		)
	  }
	
	return(
		<> 
			<Layout>
				<SEO title="Home"/>
				<div style={{zIndex:-3}}>
					<BackgroundSlider 
					callbacks = {setBg}
					images={["stray-manta.jpg", "stray-gameplay-1.png", "stray-poster.png", "ukulele.jpg", "ukulele-gameplay.png", "super-sibling-smash.png", "gtar.png", "stray-splash.png"]}
					initDelay={12}
					transition={3}
					duration={7}
					query={query}
					backgroundColor={true}		
					>
						<div ref={straySub} style={straySubStyle}><Link href="https://justiceporkgames.com/?p=stray" target="_blank" title="View Stray at JusticePorkGames.com in new tab">Stray</Link></div>					
						<div style={bgAnnotationStyle}><Link href="https://justiceporkgames.com/?p=stray" target="_blank" title="View Stray at JusticePorkGames.com in new tab">Stray</Link></div>
						<div style={bgAnnotationStyle}><Link href="https://justiceporkgames.com/?p=stray" target="_blank" title="View Stray at JusticePorkGames.com in new tab">Stray</Link></div>
						<div style={bgAnnotationStyle}><Link href="https://gabeeddyt.itch.io/ukulele" target="_blank" title="View ‘Ukulele Me‘e Itch.io page in new tab">‘Ukulele Me‘e</Link></div>					
						<div style={bgAnnotationStyle}><Link href="https://gabeeddyt.itch.io/ukulele" target="_blank" title="View ‘Ukulele Me‘e Itch.io page in new tab">‘Ukulele Me‘e</Link></div>					
						<div style={bgAnnotationStyle}><Link href="https://globalgamejam.org/2019/games/super-sibling-smasherino" target="_blank" title="View Global Game Jam page in new tab">Global Game Jam® 2019</Link></div>
						<div style={bgAnnotationStyle}><Link href="https://gabeeddyt.itch.io/gtart" target="_blank" title="View Grand Theft Artist Itch.io page in new tab">Grand Theft Artist</Link></div>
						<div style={bgAnnotationStyle}><Link href="https://justiceporkgames.com/?p=stray" target="_blank" title="View Stray at JusticePorkGames.com in new tab">Stray</Link></div>
					</BackgroundSlider>
				</div>
				<FadeIn>
					<div style={{position: 'absolute', top: '1em', left: '1em'}}>
						<Pagination callbacks={setBg}/>
					</div>
				</FadeIn>
				<Gabe></Gabe>        
				{/* <Background/> */}
			</Layout>
			<div ref={bgDivRef} style={initBGStyle}></div>{/* 
			<button onClick={ () => setBg.prev() }>prev</button>
			<button onClick={ () => setBg.next() }>next</button>  */}			
		</>
	)
}

export default IndexPage
