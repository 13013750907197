import React, {useEffect, useCallback, useRef} from "react"
import ReactDOM from "react-dom"
import { Wave } from 'react-animated-text';
import "./gabe.css"

class Letter extends React.Component  {
    constructor(props){
        super(props);
        this.actualWave = React.createRef();
        this.wave = React.createRef();
        this.letterRef = React.createRef();
        this.state = {
            paused: true,
            effect: "verticalFadeIn",
            done: false
        };
    }
    static defaultProps = {
        letter: "",
        word: "",
        delay: 1000,
        fadeOut: true
    };    

    componentDidMount(){
        this.letterObj = this.letterRef.current;
        this.forceUpdate();

        if(this.props.fadeOut){
            setTimeout(
                () => {
                    this.setState({done: true});
                }, 
                3000
            )
            
        }
        setTimeout(
            () => {
                this.setState({paused: false});
            }, 
            this.props.delay
        )        
    }

    componentDidUpdate(){
        // Make the shit stay in the same line
        // this.wave.current.childNodes[0].style="display: inline";
        ReactDOM.findDOMNode(this.actualWave.current).style="display: inline";
        this.letterRef.current.childNodes[0].style="display: inline";

    }

    render() { 
        return (    
            <>
                <h2 ref={this.letterRef} style={{display:"inline", fontSize:"10vw"}}>
                <Wave 
                        text={this.props.letter} 
                        effect={this.state.effect}
                        speed={5}
                        effectDirection="down" 
                        paused={this.state.paused}
                        iterations={1}/>
                </h2>
                {/* <span ref={this.wave}> */}
                    {
                        this.state.done ?
                        
                        <div style={{display: "inline"}}>    
                        <Wave 
                            ref={this.actualWave}
                            effectDirection="down" 
                            text={this.props.word} 
                            effect="verticalFadeOut" 
                            paused={this.state.paused}
                            iterations={1}/>
                        </div>
                        :                        
                        <Wave 
                            ref={this.actualWave}
                            effectDirection="down" 
                            text={this.props.word} 
                            effect="verticalFadeIn" 
                            paused={this.state.paused}
                            iterations={1}/>
                    }
                {/* </span> */}
            </>
        );
    }
};

const Gameplay = () => (       
  <><Letter letter="G" delay={0} fadeOut={false}/><Letter word="ameplay" delay={100}/></>
);

const Applications = () => (
    <><Letter letter="A" delay={500} fadeOut={false}/><Letter word="pplications" delay={600}/></>
)

const Browser = () => (
    <><Letter letter="B" delay={1000} fadeOut={false}/><Letter word="rowser" delay={1100}/></>
)

const Entertainment = () => (
    <><Letter letter="E" delay={1500} fadeOut={false}/><Letter word="ntertainment" delay={1600}/></>
)

const Subtitle = () => {
    const subText = "Got game in more ways than several";
    const styling = {
        position: "fixed", 
        textAlign: "center", 
        width: "100%", 
        left: 0, bottom: "calc(50% - 5vw - 10px)", 
        margin: "auto", 
        fontSize: "3.5vw", 
        transition: "opacity 4s, bottom 2s", 
        opacity: 0,
    }

    const subtitle = useCallback((node) =>{
        // let myG = document.getElementById("wtf");
        // console.log(myG);
        // myG.addEventListener("animationend",(e)=> {
        //     if(e.elapsedTime >= 1.4){ 
        //         node.style.opacity = 1;
        //         node.style.bottom = "calc(50% - 4vw - 10px)"
        //     }
        // });
        setTimeout(() => {
            node.style.opacity = 1;
            node.style.bottom = "calc(50% - 4vw - 10px)";
        }, 6000); 
    }, []);

    return(
        <>
            <div style={styling} ref={subtitle}>{subText}</div>
        </>
    );
}

const Gabe = () => {
    let G = useRef(null);
    let A = useRef(null);
    let B = useRef(null);
    let E = useRef(null);
    const fontSize = "6vw";
    let parentRef = useRef(null);    

    // const addShadow = function(e){
    //     parentNode.classList.add("shadowed");
    //     G.current.removeEventListener("transitionend",addShadow);
    //     setTimeout(() => {
    //         parentNode.classList.remove("shadowed");
    //     }, 3000);
    // };

    useEffect(()=>{
        const addShadow = function(e){
            if(e.propertyName === "margin-bottom"){
                parentNode.classList.add("shadowed");
                G.current.removeEventListener("transitionend", addShadow);
                setTimeout(() => {
                    parentNode.classList.remove("shadowed");
                }, 2000);
            }
        };

        // const shadowAnim = (e)=>e.propertyName == "bottom" && parentNode.classList.add("shadowed");

        const parentNode = parentRef.current;                
        setTimeout(            
            ()=>{
                G.current.addEventListener("transitionend", addShadow);
                // G.current.addEventListener("transitionend", shadowAnim);

                G.current.style.left = "50%";
                G.current.style.marginLeft = "-13vw";
                G.current.style.bottom = "50%";
                G.current.style.marginBottom = "-2vw";

                A.current.style.left = "50%";
                A.current.style.marginLeft = "-5.5vw";
                A.current.style.bottom = "50%";
                A.current.style.marginBottom = "-2vw";

                B.current.style.left = "50%";
                B.current.style.marginLeft = "2vw";
                B.current.style.bottom = "50%";
                B.current.style.marginBottom = "-2vw";

                E.current.style.left = "50%";
                E.current.style.marginLeft = "9vw";
                E.current.style.bottom = "50%";
                E.current.style.marginBottom = "-2vw";

            },3000
        );   
        
        // G.current.addEventListener("transitionend",(e)=>e.propertyName == "bottom" && parentNode.classList.add("shadowed"))


        /* 
            Initially hide parent node to make sure the shit isn't 
            awkwardly visible before it should be, on the initial frame.
            Unhide after ten milliseconds.
        */ 
        setTimeout(() => {
            parentNode.style.opacity = 1;
        }, 10);
    }, []);

   

    return (   
        <>
            <div ref={parentRef} style={{color: "white", userSelect: "none", opacity: 0}} id="parent">
                <div ref={G} style={{position: "absolute", left: "0pt", bottom: "50%", marginBottom: "10vw", fontSize: fontSize}}><Gameplay/></div>
                <div ref={A} style={{position: "absolute", left: "7.5vw", bottom: "50% ", marginBottom: "0vw", fontSize: fontSize}}><Applications/></div>
                <div ref={B} style={{position: "absolute", left: "15vw", bottom: "50%", marginBottom: "-10vw", fontSize: fontSize}}><Browser/></div>
                <div ref={E} style={{position: "absolute", left: "22.5vw", bottom: "50%", marginBottom: "-20vw", fontSize: fontSize}}><Entertainment/></div>
            </div>
            <Subtitle/>
        </>
    );
}

export default Gabe;